export const searchOptions = [
    { value: 'asiatiskt', label: 'Asiatiskt' },
    { value: 'fisk', label: 'Fisk' },
    { value: 'husmanskost', label: 'Husmanskost' },
    { value: 'kyckling', label: 'Kyckling' },
    { value: 'kött', label: 'Kött' },
    { value: 'pasta', label: 'Pasta' },
    { value: 'pizza', label: 'Pizza' },
    { value: 'sushi', label: 'Sushi' },
    { value: 'veganskt', label: 'Veganskt' },
    { value: 'vegetariskt', label: 'Vegetariskt' }
];

export const sortOptions = [
    { value: 'distance_asc', label: 'Avstånd, stigande' },
    { value: 'distance_desc', label: 'Avstånd, fallande' },
    { value: 'price_asc', label: 'Pris, stigande' },
    { value: 'price_desc', label: 'Pris, fallande' },
    { value: 'name_asc', label: 'Namn, stigande' },
    { value: 'name_desc', label: 'Namn, fallande' },
];